import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <section className="article__content">
      <Seo title="404: Not found" />
      <h1>Sidan kunde inte hittas</h1>
      <p>
        Ajdå, sidan kunde tyvärr inte hittas. Någonting måste vara fel. Kontakta
        oss gärna och berätta om hur du kom hit.
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
